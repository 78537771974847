<template>
  <div>
    <el-card>
      <common-user-center-title :title="'申请提现'"></common-user-center-title>
      <el-alert
        title="提示"
        type="warning"
        :closable="false"
        description="申请提现后，系统会进行审核，大概1-2个工作日内处理完毕"
        show-icon>
      </el-alert>
      <el-form class="mt-2">
        <el-form :model="applyProfitForm" :rules="applyProfitFormRules"
                 ref="applyProfitFormRef" label-width="100px">
          <el-row>
            <el-col :span="12">
              <el-form-item label="收款人" prop="name">
                <el-input v-model="applyProfitForm.name"></el-input>
              </el-form-item>
              <el-form-item label="提现方式" prop="type">
                <el-radio-group v-model="applyProfitForm.type">
                  <el-radio :label="1">微信</el-radio>
                  <el-radio :label="2">支付宝</el-radio>
                  <el-radio :label="3">银行卡</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="收款账户" prop="account">
                <el-input v-model="applyProfitForm.account"></el-input>
              </el-form-item>
              <el-form-item v-show="applyProfitForm.type==3" label="开户银行" prop="accTargetName">
                <el-input v-model="applyProfitForm.accTargetName"></el-input>
              </el-form-item>
              <el-form-item label="提现金额" prop="money">
                <el-input type="number" v-model="applyProfitForm.money"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="applyProfitSubmit">申请提现</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import commonUserCenterTitle from '@/components/common/CommonUserCenterTitle.vue'
import { applyDistributionWithdrawalUrl } from '@/api/index'
export default {
  name: 'ApplyProfit',
  components: {
    commonUserCenterTitle
  },
  data(){
    return{
      applyProfitForm:{
        name:'',
        type:1,
        account:'',
        money:'',
        accTargetName:''
      },
      applyProfitFormRules:{
        name: [
          { required: true, message: '请输入收款人', trigger: 'blur' },
        ],
        type: [
          { required: true, message: '请选择提现方式', trigger: 'change' }
        ],
        account: [
          { required: true, message: '请输入收款账户', trigger: 'blur' },
        ],
        money: [
          { required: true, message: '请输入提现金额', trigger: 'blur' },
        ],
      }
    }
  },
  created () {
  },
  methods:{
    // 申请提现
    applyProfitSubmit(){
      this.$refs.applyProfitFormRef.validate(async valid => {
        if (!valid) return
        // 添加品牌请求
        const { data: res } = await this.$http.post(applyDistributionWithdrawalUrl, this.applyProfitForm)
        if (res.code !== 200) {
          this.$message.error('申请提现失败' + res.msg)
          return false
        }
        this.$message.success('申请提现成功')
      })
    }
  }
}
</script>

<style scoped>

</style>
